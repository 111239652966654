<template>
    <v-layout column fill-height>
        <v-list>
            <v-list-tile v-for="(action, index) in actions" :key="index" :value="index == selectedIndex" @click="goTo(action.route)">
                <v-list-tile-content>
                    <v-list-tile-title v-text="action.name" />
                </v-list-tile-content>
            </v-list-tile>
        </v-list>
    </v-layout>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
    name: 'NavDrawer',
    mixins: [SuperAdminModuleGuard],
    data: function () {
        return {
            actions: [
                {
                    name:  this.$t('sa.support.actions.example'),
                    route: 'sa-support-example'
                },
                {
                    name:  this.$t('sa.support.actions.offers-template-duplicate.title'),
                    route: 'sa-support-offers-template-duplicate'
                },
                {
                    name:  this.$t('sa.support.actions.change-geds-of-vendors.title'),
                    route: 'sa-support-change-geds-of-vendors'
                },
                 {
                    name:  this.$t('sa.support.actions.send-document-to-third-app.title'),
                    route: 'sa-support-send-document-to-third-app'
                },
                {
                    name:  this.$t('sa.support.actions.add-admin-group-role-to-user.title'),
                    route: 'sa-support-add-admin-group-role-to-user'
                },
                {
                    name:  this.$t('sa.support.actions.delete-node-surveys.title'),
                    route: 'sa-support-delete-node-surveys'
                },
                {
                    name:  this.$t('sa.support.actions.affect-theme-to-users.title'),
                    route: 'sa-support-affect-theme-to-users'
                },
                {
                    name:  this.$t('sa.support.actions.catalog-tree-structure-duplicate.title'),
                    route: 'sa-support-catalog-tree-structure-duplicate'
                },
                {
                    name:  this.$t('sa.support.actions.accounting-firm-own-user-delete.title'),
                    route: 'sa-support-accounting-firm-own-user-delete'
                },
                {
                    name:  this.$t('sa.support.actions.send-invitation-to-user-vendor.title'),
                    route: 'sa-support-send-invitation-to-user-vendor'
                },
                {
                    name:  this.$t('sa.support.actions.clean-quickfolders.title'),
                    route: 'sa-support-clean-quick-folders'
                },
                {
                    name:  this.$t('sa.support.actions.diagnostic.title'),
                    route: 'sa-support-diagnostic-ged'
                }
            ]
        }
    },
    computed: {
        selectedIndex: function () {
            const routeName = this.$route.name

            const index = this.actions.findIndex(action => action.route == routeName)

            return index == -1 ? null : index
        }
    },
    methods: {
        goTo: function (route) {
            this.appService.goTo(route)
        }
    }
}
</script>